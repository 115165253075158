var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-sheet',{staticClass:"d-flex pa-4 align-center",attrs:{"color":_vm.$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-card-subtitle',{staticClass:"pa-0 col-md-6"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-alert-circle")]),_vm._v(" Resumen Últimas Incidencias ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"filled":"","hide-details":"","append-icon":"mdi-magnify","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"fixed-header":"","items":_vm.ultimasIncidencias,"headers":_vm.headers,"calculate-widths":true,"checkbox-color":"secondary","hide-default-footer":"","item-key":"id"},scopedSlots:_vm._u([{key:"item.codigoContrato",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":("/contratos/detalles?codigoContrato=" + (item.codigoContrato) + "&idContrato=" + (item.idContrato)),"color":"secondary","text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(Math.abs(Number(item.codigoContrato))))])]}}],null,true)},[_c('span',[_vm._v("Detalles del contrato")])])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("" + (item.estado == 'Cerrada' ? 'error--text' : 'success--text')),domProps:{"textContent":_vm._s(item.estado)}})]}},{key:"item.acciones",fn:function(ref){
				var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","outlined":""},on:{"click":function($event){return _vm.$router.push({
						name: 'Incidencias',
						query: {
							idIncidencia: item.id,
							codigoContrato: item.codigoContrato,
							idContrato: item.idContrato,
						},
					})}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-eye")]),_vm._v("ver detalles ")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }