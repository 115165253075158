<template>
	<v-card outlined>
		<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4 align-center">
			<v-row no-gutters align="center">
				<v-card-subtitle class="pa-0 col-md-6">
					<v-icon small>mdi-alert-circle</v-icon>&nbsp;Resumen Últimas
					Incidencias
				</v-card-subtitle>
				<v-spacer />
				<v-text-field filled hide-details append-icon="mdi-magnify" dense v-model="search" />
			</v-row>
		</v-sheet>
		<v-data-table
			fixed-header
			:items="ultimasIncidencias"
			:headers="headers"
			:calculate-widths="true"
			checkbox-color="secondary"
			hide-default-footer
			item-key="id"
		>
			<template v-slot:item.codigoContrato="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							v-bind="attrs"
							link
							:to="`/contratos/detalles?codigoContrato=${item.codigoContrato}&idContrato=${item.idContrato}`"
							color="secondary"
							text
						>{{ Math.abs(Number(item.codigoContrato)) }}</v-btn>
					</template>
					<span>Detalles del contrato</span>
				</v-tooltip>
			</template>

			<template v-slot:item.estado="{ item }">
				<span
					v-text="item.estado"
					:class="`${item.estado == 'Cerrada' ? 'error--text' : 'success--text'
					}`"
				/>
			</template>

			<template v-slot:item.acciones="{ item }">
				<v-btn
					color="primary"
					small
					outlined
					@click="
						$router.push({
							name: 'Incidencias',
							query: {
								idIncidencia: item.id,
								codigoContrato: item.codigoContrato,
								idContrato: item.idContrato,
							},
						})
					"
				>
					<v-icon left small>mdi-eye</v-icon>ver detalles
				</v-btn>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>

export default {
	data() {
		return {
			search: null,
			ultimasIncidencias: [],
			headers: [
				{ text: "Número", value: "id" },
				{ text: "Contrato", value: "codigoContrato" },
				{ text: "Estado", value: "estado" },
				{ text: "Acciones", value: "acciones" },
			],
			idIncidencia: null,
		};
	},
	mounted() {
		let str = this.$store.getters.getDatosEmpresa;
		const { IdAgente } = str.iss;
		axios({
			method: "GET",
			url: `${process.env.VUE_APP_OUR_API_URL}/incidencias.php`,
			params: {
				token: this.$store.getters.getJwtEmpresa,
				codigoContrato: this.$route.query.codigoContrato,
				idAgente: IdAgente,
			},
		}).then(({ data }) => {
			this.ultimasIncidencias = data.incidencias
				.sort((a, b) => (a.fecha > b.fecha ? -1 : 1))
				.slice(0, 5);
			this.loading = false;
		});
	},
};
</script>

<style>
</style>